import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../services/ApiService/ApiService';
import { notificationService } from '../../services/Notifications/NotificationService';

// Models
import { SignalRAction } from '../../models/SignalRAction';
import { PassTemplate } from '../../models/PassTemplate';

// Redux
import {
  createPassTemplate,
  passTemplateCreated,
  passTemplateNotCreated,
  updatePassTemplate,
  passTemplateUpdated,
  passTemplateNotUpdated,
  deletePassTemplate,
  passTemplateDeleted,
  passTemplateNotDeleted,
  deletePassTemplates,
  passTemplatesDeleted,
  passTemplatesNotDeleted,
  fetchPassTemplates,
  passTemplatesFetched,
  passTemplatesNotFetched,
} from './PassTemplates.redux';

// **************************************************
// ********************* CREATE *********************

// Worker Sagas
function* createPassTemplateSaga() {
  yield takeEvery(createPassTemplate.type, createPassTemplateRequest);
}

function* passTemplateCreatedSaga() {
  yield takeLatest(passTemplateCreated.type, createPassTemplateResponse);
}

function* passTemplateNotCreatedSaga() {
  yield takeLatest(passTemplateNotCreated.type, createPassTemplateError);
}

// Request
function* createPassTemplateRequest(action: PayloadAction<PassTemplate>) {
  try {
    const { payload: passTemplate } = action;
    yield apiService.execute({
      url: 'PassTemplates',
      method: ApiRequestType.POST,
      data: passTemplate,
    });
  } catch ({ message }) {
    yield put({ type: passTemplateNotCreated.type, payload: { msg: { message } } });
  }
}

// Response
function createPassTemplateResponse() {
  notificationService.showSuccess('passTemplates.notifications.create');
}

// Error
function createPassTemplateError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('passTemplates.notifications.createFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* UPDATE *********************

// Worker Sagas
function* updatePassTemplateSaga() {
  yield takeEvery(updatePassTemplate.type, updatePassTemplateRequest);
}

function* passTemplateUpdatedSaga() {
  yield takeLatest(passTemplateUpdated.type, updatePassTemplateResponse);
}

function* passTemplateNotUpdatedSaga() {
  yield takeLatest(passTemplateNotUpdated.type, updatePassTemplateError);
}

// Request
function* updatePassTemplateRequest(action: PayloadAction<PassTemplate>) {
  try {
    const { payload: passTemplate } = action;

    yield apiService.execute({
      url: `PassTemplates/${passTemplate.Id}`,
      method: ApiRequestType.PUT,
      data: passTemplate,
    });
  } catch ({ message }) {
    yield put({ type: passTemplateNotUpdated.type, payload: { msg: { message } } });
  }
}

// Response
function updatePassTemplateResponse() {
  notificationService.showSuccess('passTemplates.notifications.update');
}

// Error
function updatePassTemplateError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('passTemplates.notifications.updateFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* DELETE *********************

// Worker Sagas
function* deletePassTemplateSaga() {
  yield takeEvery(deletePassTemplate.type, deletePassTemplateRequest);
}

function* passTemplateDeletedSaga() {
  yield takeLatest(passTemplateDeleted.type, deletePassTemplateResponse);
}

function* passTemplateNotDeletedSaga() {
  yield takeLatest(passTemplateNotDeleted.type, deletePassTemplateError);
}

// Request
function* deletePassTemplateRequest(action: PayloadAction<PassTemplate>) {
  try {
    const { payload: passTemplate } = action;
    yield apiService.execute({
      url: `PassTemplates/${passTemplate.Id}`,
      method: ApiRequestType.DELETE,
      data: passTemplate,
    });
  } catch ({ message }) {
    yield put({ type: passTemplateNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deletePassTemplateResponse(action: PayloadAction<SignalRAction>) {
  notificationService.showSuccess('passTemplates.notifications.delete');
  action.payload.history.push(`/PassTemplates`);
}

// Error
function deletePassTemplateError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('passTemplates.notifications.deleteFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* DELETE All *****************

// Worker Sagas
function* deletePassTemplatesSaga() {
  yield takeEvery(deletePassTemplates.type, deletePassTemplatessRequest);
}

function* passTemplatesDeletedSaga() {
  yield takeLatest(passTemplatesDeleted.type, deletePassTemplatesResponse);
}

function* passTemplatesNotDeletedSaga() {
  yield takeLatest(passTemplatesNotDeleted.type, deletePassTemplatesError);
}

// Request
function* deletePassTemplatessRequest(action: PayloadAction<Array<PassTemplate>>) {
  try {
    const { payload: passTemplates } = action;
    yield apiService.execute({
      url: `PassTemplates`,
      method: ApiRequestType.DELETE,
      data: { ids: passTemplates.map((passTemplate) => passTemplate.Id) },
    });
  } catch ({ message }) {
    yield put({ type: passTemplatesNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deletePassTemplatesResponse() {
  notificationService.showSuccess('passTemplates.notifications.deleteAll');
}

// Error
function deletePassTemplatesError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('passTemplates.notifications.deleteAllFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* FETCH *********************

// Worker Sagas
function* fetchPassTemplatesSaga() {
  yield takeEvery(fetchPassTemplates.type, fetchPassTemplatesRequest);
}

function* passTemplatesFetchedSaga() {
  yield takeLatest(passTemplatesFetched.type, fetchPassTemplatesResponse);
}

function* passTemplatesNotFetchedSaga() {
  yield takeLatest(passTemplatesNotFetched.type, fetchPassTemplatesError);
}

// Request
function* fetchPassTemplatesRequest(action: PayloadAction<PassTemplate>) {
  try {
    const { payload: passTemplate } = action;
    yield apiService.execute({
      url: `PassTemplates/Fetch/${passTemplate.Id}`,
      method: ApiRequestType.POST,
    });
  } catch ({ message }) {
    yield put({ type: passTemplatesNotFetched.type, payload: { msg: { message } } });
  }
}

// Response
function fetchPassTemplatesResponse() {
  notificationService.showSuccess('passTemplates.notifications.fetch');
}

// Error
function fetchPassTemplatesError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('passTemplates.notifications.fetchFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Create
    createPassTemplateSaga(),
    passTemplateCreatedSaga(),
    passTemplateNotCreatedSaga(),
    // Update
    updatePassTemplateSaga(),
    passTemplateUpdatedSaga(),
    passTemplateNotUpdatedSaga(),
    // Delete
    deletePassTemplateSaga(),
    passTemplateDeletedSaga(),
    passTemplateNotDeletedSaga(),
    // Delete All
    deletePassTemplatesSaga(),
    passTemplatesDeletedSaga(),
    passTemplatesNotDeletedSaga(),
    // Fetch
    fetchPassTemplatesSaga(),
    passTemplatesFetchedSaga(),
    passTemplatesNotFetchedSaga(),
  ]);
}
