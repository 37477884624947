import React, { useCallback, useMemo, useState } from 'react';
import { Checkbox, Col, Divider, Form, Input, Modal, Radio, Row, Select, UploadFile } from 'antd';
import { InboxOutlined, UserDeleteOutlined } from '@ant-design/icons';
import { Store } from 'antd/lib/form/interface';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Row as TableRow } from 'react-table';

// Types
import Title from 'antd/lib/typography/Title';
import Dragger from 'antd/lib/upload/Dragger';
import { FormOptions, UseFormProps } from '../../types/Table';

// Models
import { PassTemplate } from '../../models/PassTemplate';

// Hooks
import { useAppDispatch, useAppSelector } from '../App/useRedux';

// Actions
import {
  createPassTemplate,
  deletePassTemplate,
  deletePassTemplates,
  updatePassTemplate,
} from '../../store/PassTemplates/PassTemplates.redux';

// Components
import { Translated } from '../../components/UI/Core';
import { Spinner } from '../../components/UI/Spinner/Spinner';
import { Flex } from '../../components/UI/Base';
import { FormColumn } from '../../components/UI/Form/FormColumn';
import { PassTemplateTypes } from '../../models/enums/PassTemplates/PassTemplateType';
import { PassTemplateOrientations } from '../../models/enums/PassTemplates/PassTemplateOrientation';
import {
  PassTemplateBackgrounds,
  PassTemplateBackgroundType,
} from '../../models/enums/PassTemplates/PassTemplateBackground';
import ColorPicker from '../../components/UI/Form/ColorPicker';
import { BackgroundSizes } from '../../models/enums/BackgroundSize';

const { confirm } = Modal;

// Styled
const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

// Props
interface PassTemplatesReduxProps {
  isEditing?: boolean;
  initialValues?: Store;
}

// Hooks
export const usePassTemplatesRedux = <T extends object>({ isEditing, initialValues }: PassTemplatesReduxProps = {}) => {
  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(({ passTemplates }) => passTemplates?.updating ?? false);
  const error = useAppSelector(({ passTemplates }) => passTemplates?.error ?? false);
  const [backgroundFile, setBackgroundFile] = useState<string | undefined>();

  // Submit Handling
  const onSubmit = useCallback(
    (passTemplate: PassTemplate) => {
      if (isEditing) {
        const passTemplateToUpdate = initialValues as PassTemplate;
        passTemplateToUpdate.Name = passTemplate.Name;
        passTemplateToUpdate.EnabledForVisitors = passTemplate.EnabledForVisitors;
        passTemplateToUpdate.EnabledForEmployees = passTemplate.EnabledForEmployees;
        passTemplateToUpdate.Type = passTemplate.Type;
        passTemplateToUpdate.Orientation = passTemplate.Orientation;
        passTemplateToUpdate.BackgroundColor = passTemplate.BackgroundColor;
        passTemplateToUpdate.BackgroundType = passTemplate.BackgroundType;
        passTemplateToUpdate.BackgroundSize = passTemplate.BackgroundSize;
        passTemplateToUpdate.BackgroundImage.FileData = backgroundFile;

        dispatch(updatePassTemplate(passTemplateToUpdate));
      } else {
        dispatch(createPassTemplate(passTemplate));
      }
    },
    [dispatch, isEditing, initialValues, backgroundFile]
  );

  // Components
  const CreatePassTemplateForm = useCallback(
    ({ form }: UseFormProps<T>) => (
      <StyledForm
        form={form}
        layout="vertical"
        onFinish={(values) => onSubmit(values as PassTemplate)}
        initialValues={initialValues}
      >
        <Spinner spinning={updating}>
          <Form.Item name="Id" hidden>
            <Input />
          </Form.Item>
          <Row>
            <Col>
              <Form.Item
                name="Name"
                label={<Translated id="passTemplates.name" />}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'passTemplates.form.warnings.name' }),
                  },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            </Col>
          </Row>
        </Spinner>
      </StyledForm>
    ),
    [onSubmit, updating, initialValues, intl]
  );

  const toBase64 = useCallback(
    (file: File) =>
      new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = reject;
      }),
    []
  );

  const passTemplateInformation = useMemo(
    () => (
      <FormColumn padRightNone xs={24} md={13}>
        {/* Header */}
        <Flex alignItems="center" className="gx-mb-2">
          <Title level={4} className="gx-mb-0" style={{ fontWeight: 500 }}>
            <Translated id="passTemplates.form.basicInformation" />
          </Title>
        </Flex>
        <Divider />

        <Form.Item
          name="Name"
          label={<Translated id="passTemplates.form.name" />}
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'passTemplates.form.warnings.name' }),
            },
          ]}
        >
          <Input placeholder="Name" />
        </Form.Item>

        <div style={{ marginBottom: 5 }}>
          <Translated id="passTemplates.form.enabledFor" />
        </div>
        <Row style={{ flexDirection: 'row' }}>
          <Col span={7} style={{ padding: 0, margin: 0 }}>
            <Form.Item name="EnabledForEmployees" valuePropName="checked">
              <Checkbox>
                <Translated id="passTemplates.employees" />
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={7} style={{ padding: 0, margin: 0 }}>
            <Form.Item name="EnabledForVisitors" valuePropName="checked">
              <Checkbox>
                <Translated id="passTemplates.visitors" />
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name="Type"
          label={<Translated id="passTemplates.form.size" />}
          rules={[
            {
              required: false,
              message: intl.formatMessage({ id: 'passTemplates.form.warnings.size' }),
            },
          ]}
        >
          <Select placeholder={<Translated id="passTemplates.size" />}>
            {PassTemplateTypes?.map((item) => (
              <Select.Option key={item.Value} value={item.Value}>
                <Translated id={item.Name} />
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <div style={{ marginBottom: 10 }}>
          <Translated id="passTemplates.form.orientation" />
        </div>
        <Form.Item name="Orientation">
          <Radio.Group buttonStyle="solid" style={{ display: 'flex' }}>
            <Radio.Button value={PassTemplateOrientations[0].Value} style={{ flex: 1 }}>
              <Flex justifyContent="space-between" alignItems="center">
                <Translated id="passTemplates.landscape" />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="1"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <rect width="20" height="12" x="2" y="6" rx="2" />
                </svg>
              </Flex>
            </Radio.Button>
            <Radio.Button value={PassTemplateOrientations[1].Value} style={{ flex: 1 }}>
              <Flex justifyContent="space-between" alignItems="center">
                <Translated id="passTemplates.portrait" />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="1"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <rect width="12" height="20" x="6" y="2" rx="2" />
                </svg>
              </Flex>
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
      </FormColumn>
    ),
    [intl]
  );

  const passTemplateStyling = useCallback(
    ({ form }: UseFormProps<T>) => {
      const onBackgroundUpload = async (file?: File) => {
        if (file) {
          setBackgroundFile(await toBase64(file));
        }
      };

      return (
        <FormColumn padLeftNone xs={24} md={10}>
          {/* Header */}
          <Flex alignItems="center" className="gx-mb-2">
            <Title level={4} className="gx-mb-0" style={{ fontWeight: 500 }}>
              <Translated id="passTemplates.form.passStyling" />
            </Title>
          </Flex>
          <Divider />

          <div style={{ marginBottom: 10 }}>
            <Translated id="passTemplates.form.backgroundType" />
          </div>
          <Form.Item name="BackgroundType">
            <Radio.Group buttonStyle="solid" style={{ display: 'flex' }}>
              <Radio.Button value={PassTemplateBackgrounds[0].Value} style={{ flex: 1 }}>
                <Translated id="passTemplates.image" />
              </Radio.Button>
              <Radio.Button value={PassTemplateBackgrounds[1].Value} style={{ flex: 1 }}>
                <Translated id="passTemplates.color" />
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item shouldUpdate>
            {() =>
              form.getFieldValue('BackgroundType') === PassTemplateBackgroundType.Image ? (
                <>
                  <Form.Item
                    name="BackgroundSize"
                    label={<Translated id="passTemplates.form.backgroundSize" />}
                    rules={[
                      {
                        required: false,
                        message: intl.formatMessage({ id: 'passTemplates.form.warnings.backgroundSize' }),
                      },
                    ]}
                  >
                    <Select placeholder={<Translated id="passTemplates.backgroundSize" />}>
                      {BackgroundSizes?.map((item) => (
                        <Select.Option key={item.Value} value={item.Value}>
                          <Translated id={item.Name} />
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name="BackgroundImage" label={<Translated id="passTemplates.form.backgroundImage" />}>
                    <Dragger
                      name="file"
                      multiple={false}
                      action=""
                      listType="picture"
                      beforeUpload={() => false}
                      fileList={
                        backgroundFile
                          ? [
                              {
                                uid: 'PTBI',
                                name: 'Background Image',
                                fileName: 'BackgroundImage',
                                thumbUrl: backgroundFile,
                              } as UploadFile,
                            ]
                          : []
                      }
                      onChange={async (event) => {
                        await onBackgroundUpload(event.fileList[event.fileList.length - 1]?.originFileObj);
                      }}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        <Translated id="passTemplates.upload.backgroundImage.text" />
                      </p>
                      <p className="ant-upload-hint">
                        <Translated id="passTemplates.upload.backgroundImage.text1" />
                      </p>
                    </Dragger>
                  </Form.Item>
                </>
              ) : (
                <Form.Item name="BackgroundColor" label={<Translated id="passTemplates.form.backgroundColor" />}>
                  <ColorPicker name="BackgroundColor" form={form} />
                </Form.Item>
              )
            }
          </Form.Item>
        </FormColumn>
      );
    },
    [backgroundFile, intl, toBase64]
  );

  const EditPassTemplateForm = useCallback(
    (form: UseFormProps<T>) => {
      return (
        <StyledForm
          form={form.form}
          layout="vertical"
          onFinish={(values) => onSubmit(values as PassTemplate)}
          initialValues={initialValues}
        >
          <Spinner spinning={updating}>
            <Form.Item name="Id" hidden>
              <Input />
            </Form.Item>

            <Row style={{ flexDirection: 'row' }}>
              {/* Basic Information */}
              {passTemplateInformation}

              {/* Responsive Dividers */}
              <Col style={{ padding: 0 }} xs={0} md={1}>
                <Flex justifyContent="center" style={{ height: '100%' }}>
                  <Divider type="vertical" style={{ height: '100%' }} />
                </Flex>
              </Col>
              <Col style={{ padding: 0 }} xs={24} md={0}>
                <Flex justifyContent="center" style={{ width: '100%' }}>
                  <Divider type="horizontal" style={{ width: '100%' }} />
                </Flex>
              </Col>

              {/* Styling */}
              {passTemplateStyling(form)}
            </Row>
          </Spinner>
        </StyledForm>
      );
    },
    [initialValues, updating, passTemplateInformation, passTemplateStyling, onSubmit]
  );

  // Form options
  const formOptions = useMemo(
    () =>
      ({
        endpoint: 'PassTemplates',
        Form: isEditing ? EditPassTemplateForm : CreatePassTemplateForm,
        labels: {
          createButton: <Translated id="passTemplates.form.create" />,
          drawerForm: <Translated id={isEditing ? 'passTemplates.form.edit' : 'passTemplates.form.create'} />,
          submitButton: <Translated id={isEditing ? 'form.editButton' : 'form.createButton'} />,
        },
      } as FormOptions<T>),
    [CreatePassTemplateForm, EditPassTemplateForm, isEditing]
  );

  // Confirmation Modals
  const showDeleteConfirm = useCallback(
    (passTemplate: PassTemplate | undefined) => {
      if (passTemplate) {
        confirm({
          title: intl.formatMessage({
            id: 'passTemplates.confirm.delete',
          }),
          icon: <UserDeleteOutlined />,
          content: intl.formatMessage({
            id: 'passTemplates.confirm.deleteSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'danger',
          onOk: () => dispatch(deletePassTemplate(passTemplate)),
          onCancel: () => null,
        });
      }
    },
    [intl, dispatch]
  );

  const showDeleteAllConfirm = useCallback(
    <TRowObject extends object>(selectedFlatRows: Array<TableRow<TRowObject>>) => {
      const passTemplates = selectedFlatRows.map((d) => d.original) as PassTemplate[];
      if (passTemplates) {
        confirm({
          title: intl.formatMessage({
            id: 'passTemplates.confirm.deleteAll',
          }),
          icon: <UserDeleteOutlined />,
          content: intl.formatMessage({
            id: 'passTemplates.confirm.deleteAllSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'danger',
          onOk: () => dispatch(deletePassTemplates(passTemplates)),
          onCancel: () => null,
        });
      }
    },
    [intl, dispatch]
  );

  const drawerSize: 'default' | 'large' = isEditing ? 'large' : 'default';

  return useMemo(
    () => ({
      formOptions,
      updating,
      error,
      size: drawerSize,
      showDeleteConfirm,
      showDeleteAllConfirm,
    }),
    [formOptions, updating, error, showDeleteConfirm, showDeleteAllConfirm, drawerSize]
  );
};
