import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { Store } from 'antd/lib/form/interface';
import { usePrevious } from 'react-use';

// Hooks
import { usePassTemplatesRedux } from './usePassTemplatesRedux';
import { useFormDrawer } from '../Drawer/useFormDrawer';
import { useData } from '../App/useData';

// Services
import { notificationService } from '../../services/Notifications/NotificationService';

// Data
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { appBreadcrumbs } from '../../data/Breadcrumbs/Breadcrumbs';
import { PassTemplate } from '../../models/PassTemplate';

// Props
interface PassTemplateDetailProps {
  id?: string;
}

export const usePassTemplateDetail = <T extends object>({ id }: PassTemplateDetailProps) => {
  // Navigation Hook
  const navigate = useNavigate();

  // Data
  const { data: passTemplateResponse, fetch, pending } = useData(ApiEndpoints.passTemplates.detail, null);
  const prevPending = usePrevious(pending);

  // Component State
  const [passTemplateData, setPassTemplateData] = useState<PassTemplate>();

  // Redux
  const { formOptions, updating, error, showDeleteConfirm, size } = usePassTemplatesRedux<T>({
    isEditing: true,
    initialValues: passTemplateResponse as Store,
  });
  const prevUpdating = usePrevious(updating);

  // Form Drawer
  const { getFormDrawerProps } = useFormDrawer<T>({ formOptions, updating, error, size });

  // Props
  const getBreadcrumbMenuProps = useCallback(() => ({ breadcrumbs: appBreadcrumbs.passTemplates.detail }), []);
  const getProfileHeaderProps = useCallback(
    () => ({
      passTemplateData,
      pending,
      setOpen: getFormDrawerProps().setOpen,
      showDeleteConfirm,
    }),
    [passTemplateData, pending, getFormDrawerProps, showDeleteConfirm]
  );

  const getContactCardProps = useCallback(
    () => ({
      passTemplate: passTemplateResponse,
      pending,
    }),
    [passTemplateResponse, pending]
  );

  const getPassTemplateTenantsProps = useCallback(
    () => ({
      passTemplateResponse,
      fetchPassTemplate: fetch,
      updatingPassTemplate: pending,
    }),
    [passTemplateResponse, fetch, pending]
  );

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetch({ id });
  }, [fetch, id]);

  useEffect(() => {
    // Navigates to the index if not found
    if (prevPending === true && !pending) {
      if (!passTemplateResponse) {
        notificationService.showError('passTemplates.notFound');
        navigate('/PassTemplates');
      }
      // Update Component State based on API response
      else {
        setPassTemplateData(passTemplateResponse);
      }
    }
  }, [prevPending, pending, passTemplateResponse, navigate]);

  useEffect(() => {
    // Fetch after updating
    if (prevUpdating === true && !updating && !error) {
      fetch({ id });
    }
  }, [prevUpdating, updating, error, fetch, id, size]);

  return useMemo(
    () => ({
      updating,
      getBreadcrumbMenuProps,
      getProfileHeaderProps,
      getContactCardProps,
      getPassTemplateTenantsProps,
      getFormDrawerProps,
    }),
    [
      updating,
      getBreadcrumbMenuProps,
      getProfileHeaderProps,
      getContactCardProps,
      getPassTemplateTenantsProps,
      getFormDrawerProps,
    ]
  );
};
