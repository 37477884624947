// Types
import { SignalREvent } from '../../models/SignalREvent';

// Actions
import {
  passTemplateCreated,
  passTemplateDeleted,
  passTemplateNotCreated,
  passTemplateNotDeleted,
  passTemplateNotUpdated,
  passTemplateUpdated,
} from './PassTemplates.redux';
import { OperationType } from '../../models/SignalRAction';

// Events
const SignalREvents = {
  PassTemplateOperation: 'PassTemplateOperationIntegrationEvent',
  PassTemplateTenantsAssigned: `PassTemplateTenantsAssignedIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  PassTemplateOperationFailed: 'PassTemplateOperationIntegrationException',
  PassTemplateTenantsNotAssigned: `PassTemplateTenantsNotAssignedIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // CRUD Operations
  hubConnection.on(SignalREvents.PassTemplateOperation, (msg) => {
    const op = msg.operation as OperationType;
    switch (op) {
      case OperationType.Create:
        store.dispatch(passTemplateCreated({ history, msg }));
        break;
      case OperationType.Update:
        store.dispatch(passTemplateUpdated({ history, msg }));
        break;
      case OperationType.Delete:
        store.dispatch(passTemplateDeleted({ history, msg }));
        break;
      default:
        break;
    }
  });
  hubConnection.on(SignalRExceptions.PassTemplateOperationFailed, (msg) => {
    const op = msg.operation as OperationType;
    switch (op) {
      case OperationType.Create:
        store.dispatch(passTemplateNotCreated({ history, msg }));
        break;
      case OperationType.Update:
        store.dispatch(passTemplateNotUpdated({ history, msg }));
        break;
      case OperationType.Delete:
        store.dispatch(passTemplateNotDeleted({ history, msg }));
        break;
      default:
        break;
    }
  });
  // Assign Tenants
  // hubConnection.on(SignalREvents.PassTemplateTenantsAssigned, (msg) => {
  //   store.dispatch(readersAssigned({ history, msg }));
  // });
  // hubConnection.on(SignalRExceptions.PassTemplateTenantsNotAssigned, (msg) => {
  //   store.dispatch(tenantsnot({ history, msg }));
  // });
};
