import React from 'react';

import { TableColumn } from '../../types/Table';
import { Translated } from '../../components/UI/Core';
import { PassTemplate } from '../../models/PassTemplate';

export const passTemplateTableColumns: Array<TableColumn<PassTemplate>> = [
  {
    Header: <Translated id="passTemplates.id" />,
    id: 'Id',
    accessor: 'Id',
    isVisible: false,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: <Translated id="passTemplates.name" />,
    id: 'Name',
    accessor: 'Name',
  },
];
