import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Modal } from 'antd';
import { IdcardOutlined } from '@ant-design/icons';

// Models
import styled from 'styled-components';
import { Tenant } from '../../../models/Tenant';

// Redux
import { useAppDispatch, useAppSelector } from '../../App/useRedux';

// Components
import { Translated } from '../../../components/UI/Core';
import { ItemTransfer, TransferItem } from '../../../components/UI/Transfer/ItemTransfer';
import { PassTemplate } from '../../../models/PassTemplate';
import { assignPassTemplateTenants } from '../../../store/PassTemplates/PassTemplateTenants/PassTemplateTenants.redux';

const { confirm } = Modal;

// Props
interface PassTemplateTenantsFormProps {
  passTemplateTenantsResponse: PassTemplate | null;
  passTemplateTenants: Array<Tenant> | null;
}

interface AssignConfirmProps {
  tenants: Array<Tenant>;
  passTemplate: PassTemplate;
}

// Styled
const StyledButton = styled(Button)`
  margin-bottom: 0;
`;

// Hook
export const usePassTemplateTenantsRedux = ({
  passTemplateTenantsResponse,
  passTemplateTenants,
}: PassTemplateTenantsFormProps) => {
  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(
    ({ passTemplateTenants: passTemplateTenantsState }) => passTemplateTenantsState?.updating ?? false
  );
  const error = useAppSelector(
    ({ passTemplateTenants: passTemplateTenantsState }) => passTemplateTenantsState?.error ?? false
  );

  // Confirmation Modals
  const showAssignConfirm = useCallback(
    ({ tenants, passTemplate }: AssignConfirmProps) => {
      if (tenants && passTemplate) {
        confirm({
          title: intl.formatMessage({
            id: 'passTemplates.Tenants.multiSelect.confirm.assign',
          }),
          icon: <IdcardOutlined />,
          content: intl.formatMessage({
            id: 'passTemplates.Tenants.multiSelect.confirm.assignSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'primary',
          onOk: () => dispatch(assignPassTemplateTenants({ tenants, passTemplate })),
          onCancel: () => null,
        });
      }
    },
    [intl, dispatch]
  );

  // State
  const [tenantIds, setTenantIds] = useState<Array<string>>([]);

  // Sets the Keys when the response is updated
  // Fixes problem with initial values after API call
  useEffect(() => {
    setTenantIds(
      passTemplateTenants
        ?.filter((tenant) =>
          passTemplateTenantsResponse?.Tenants?.some(
            (passTemplateTenant) =>
              passTemplateTenant.CompanyId === tenant.CompanyId &&
              passTemplateTenant.LocationId === tenant.LocationId &&
              passTemplateTenant.BusinessClientId === tenant.BusinessClientId
          )
        )
        .map((tenant) => tenant.Id) ?? []
    );
  }, [passTemplateTenants, passTemplateTenantsResponse]);

  // Components
  const PassTemplateTenantsManagementForm = useCallback(() => {
    // Get all Companies as MultiSelectItems
    const dataSource =
      passTemplateTenants?.map<TransferItem>((passTemplateTenant) => ({
        key: passTemplateTenant.Id,
        title: passTemplateTenant.Name,
        description: '',
      })) ?? [];

    return (
      <>
        <ItemTransfer dataSource={dataSource} targetKeys={tenantIds} setTargetKeys={setTenantIds} />
        <StyledButton
          type="primary"
          onClick={() =>
            passTemplateTenantsResponse &&
            passTemplateTenants &&
            showAssignConfirm({
              tenants: passTemplateTenants.filter((t) => tenantIds.some((id) => id === t.Id)),
              passTemplate: passTemplateTenantsResponse,
            })
          }
        >
          <Translated id="form.confirmButton" />
        </StyledButton>
      </>
    );
  }, [passTemplateTenantsResponse, passTemplateTenants, showAssignConfirm, tenantIds, setTenantIds]);

  return useMemo(
    () => ({
      PassTemplateTenantsManagementForm,
      updating,
      error,
    }),
    [PassTemplateTenantsManagementForm, updating, error]
  );
};
