/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { SignalRAction } from '../../../models/SignalRAction';
import { Tenant } from '../../../models/Tenant';
import { PassTemplate } from '../../../models/PassTemplate';

// State
interface PassTemplateTenantsState {
  updating: boolean;
  error: boolean;
}

const initialState: PassTemplateTenantsState = {
  updating: false,
  error: false,
};

// Name
const storeName = '@PASS_TEMPLATE_TENANTS';

// Redux Actions|Reducers
const passTemplateTenantsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Assign
    assignPassTemplateTenants: (
      state,
      action: PayloadAction<{ passTemplate: PassTemplate; tenants: Array<Tenant> }>
    ) => {
      state.updating = true;
      state.error = false;
    },
    passTemplateTenantsAssigned: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    passTemplateTenantsNotAssigned: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Assign
  assignPassTemplateTenants,
  passTemplateTenantsAssigned,
  passTemplateTenantsNotAssigned,
} = passTemplateTenantsSlice.actions;

// Export Reducer
export default passTemplateTenantsSlice.reducer;
