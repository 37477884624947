export enum BackgroundSizeType {
  Auto = 0,
  Contain = 1,
  Cover = 2,
}

export interface BackgroundSize {
  Name: string;
  Value: number;
  Fit: BackgroundSizeType;
}

export const BackgroundSizes: BackgroundSize[] = [
  {
    Name: 'passTemplates.form.backgroundSize.auto',
    Value: 0,
    Fit: BackgroundSizeType.Auto,
  },
  {
    Name: 'passTemplates.form.backgroundSize.contain',
    Value: 1,
    Fit: BackgroundSizeType.Contain,
  },
  {
    Name: 'passTemplates.form.backgroundSize.cover',
    Value: 2,
    Fit: BackgroundSizeType.Cover,
  },
];
