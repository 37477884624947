import { useEffect, useMemo } from 'react';
import { usePrevious } from 'react-use';

// Models
import { PassTemplate } from '../../../models/PassTemplate';

// Hooks
import { useData } from '../../App/useData';
import { usePassTemplateTenantsRedux } from './usePassTemplateTenantsRedux';

// Data
import { ApiEndpoints } from '../../../data/ApiEndpoints';

// Props
interface PassTemplateTenantsProps {
  passTemplateResponse: PassTemplate | null;
  fetchPassTemplate: (params: any) => Promise<void>;
  updatingPassTemplate: boolean;
}

export const usePassTemplateTenants = ({
  passTemplateResponse: passTemplateTenantsResponse,
  fetchPassTemplate,
  updatingPassTemplate: updatingPassTemplateTenant,
}: PassTemplateTenantsProps) => {
  // Data
  const {
    data: passTemplateTenants,
    fetch: fetchPassTemplateTenants,
    pending,
  } = useData(ApiEndpoints.tenants.list, null);

  // Redux
  const { PassTemplateTenantsManagementForm, updating, error } = usePassTemplateTenantsRedux({
    passTemplateTenantsResponse,
    passTemplateTenants,
  });

  const prevUpdating = usePrevious(updating);

  // Component State
  const loading = pending && !passTemplateTenantsResponse;

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetchPassTemplateTenants();
  }, [fetchPassTemplateTenants]);

  useEffect(() => {
    // Fetch after updating
    if (passTemplateTenantsResponse && prevUpdating === true && !updating && !error) {
      fetchPassTemplate({ id: passTemplateTenantsResponse?.Id });
      fetchPassTemplateTenants();
    }
  }, [prevUpdating, updating, error, fetchPassTemplate, fetchPassTemplateTenants, passTemplateTenantsResponse]);

  // Return Hook
  return useMemo(
    () => ({
      passTemplateTenants,
      PassTemplateTenantsManagementForm,
      loading,
      updating: updating || updatingPassTemplateTenant,
    }),
    [passTemplateTenants, PassTemplateTenantsManagementForm, loading, updating, updatingPassTemplateTenant]
  );
};
