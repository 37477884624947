import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../../services/ApiService/ApiService';
import { notificationService } from '../../../services/Notifications/NotificationService';

// Models
import { SignalRAction } from '../../../models/SignalRAction';

// Redux
import {
  assignPassTemplateTenants,
  passTemplateTenantsAssigned,
  passTemplateTenantsNotAssigned,
} from './PassTemplateTenants.redux';
import { Tenant } from '../../../models/Tenant';
import { PassTemplate } from '../../../models/PassTemplate';

// **************************************************
// ********************* ASSIGN *********************

// Worker Sagas
function* assignPassTemplateTenantsSaga() {
  yield takeEvery(assignPassTemplateTenants.type, assignPassTemplateTenantsRequest);
}

function* passTemplateTenantsAssignedSaga() {
  yield takeLatest(passTemplateTenantsAssigned.type, assignPassTemplateTenantsResponse);
}

function* passTemplateTenantsNotAssignedSaga() {
  yield takeLatest(passTemplateTenantsNotAssigned.type, assignPassTemplateTenantsError);
}

// Request
function* assignPassTemplateTenantsRequest(
  action: PayloadAction<{ passTemplate: PassTemplate; tenants: Array<Tenant> }>
) {
  try {
    const {
      payload: { passTemplate, tenants },
    } = action;
    yield apiService.execute({
      url: `PassTemplates/${passTemplate.Id}/Tenants`,
      method: ApiRequestType.PUT,
      data: {
        PassTemplateId: passTemplate.Id,
        Tenants: tenants,
      },
    });
  } catch ({ message }) {
    yield put({ type: passTemplateTenantsNotAssigned.type, payload: { msg: { message } } });
  }
}

// Response
function assignPassTemplateTenantsResponse() {
  notificationService.showSuccess('passTemplates.Tenants.notifications.assign');
}

// Error
function assignPassTemplateTenantsError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'passTemplates.Tenants.notifications.assignFailed',
    action?.payload?.msg.message
  );
}

// **************************************************

// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Assign
    assignPassTemplateTenantsSaga(),
    passTemplateTenantsAssignedSaga(),
    passTemplateTenantsNotAssignedSaga(),
  ]);
}
