export enum PassTemplateOrientationType {
  Portrait = 0,
  Landscape = 1,
}

export interface PassTemplateOrientation {
  Name: string;
  Value: number;
  Orientation: PassTemplateOrientationType;
}

export const PassTemplateOrientations: PassTemplateOrientation[] = [
  {
    Name: 'Portrait',
    Value: 0,
    Orientation: PassTemplateOrientationType.Portrait,
  },
  {
    Name: 'Landscape',
    Value: 1,
    Orientation: PassTemplateOrientationType.Landscape,
  },
];
