import React from 'react';
import { Translated } from '../UI/Core';
import { Widget } from '../UI/Widget/Widget';
import { useAppSelector } from '../../hooks/App/useRedux';
import { Spinner } from '../UI/Spinner/Spinner';
import { PassTemplate } from '../../models/PassTemplate';

interface ContactCardProps {
  passTemplate: PassTemplate | null;
  pending: boolean;
}

export const ContactCard = ({ passTemplate, pending }: ContactCardProps) => {
  const { updating } = useAppSelector(({ passTemplates }) => passTemplates);

  return (
    <Widget title={<Translated id="passTemplates.assets" />} styleName="gx-card-profile-sm">
      <Spinner spinning={!!updating}>{/* Type */}</Spinner>
    </Widget>
  );
};
