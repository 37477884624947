export enum PassTemplateBackgroundType {
  Image = 0,
  Color = 1,
}

export interface PassTemplateBackground {
  Name: string;
  Value: number;
  Background: PassTemplateBackgroundType;
}

export const PassTemplateBackgrounds: PassTemplateBackground[] = [
  {
    Name: 'Image',
    Value: 0,
    Background: PassTemplateBackgroundType.Image,
  },
  {
    Name: 'Color',
    Value: 1,
    Background: PassTemplateBackgroundType.Color,
  },
];
