/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { SignalRAction } from '../../models/SignalRAction';
import { PassTemplate } from '../../models/PassTemplate';

// State
interface PassTemplateState {
  updating: boolean;
  updatingSettings: boolean;
  error: boolean;
}

const initialState: PassTemplateState = {
  updating: false,
  updatingSettings: false,
  error: false,
};

// Name
const storeName = '@PASS_TEMPLATE';

// Redux Actions|Reducers
const PassTemplateSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Create
    createPassTemplate: (state, action: PayloadAction<PassTemplate>) => {
      state.updating = true;
      state.error = false;
    },
    passTemplateCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    passTemplateNotCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Update
    updatePassTemplate: (state, action: PayloadAction<PassTemplate>) => {
      state.updating = true;
      state.error = false;
    },
    passTemplateUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    passTemplateNotUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Delete
    deletePassTemplate: (state, action: PayloadAction<PassTemplate>) => {
      state.updating = true;
      state.error = false;
    },
    passTemplateDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    passTemplateNotDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Delete All
    deletePassTemplates: (state, action: PayloadAction<Array<PassTemplate>>) => {
      state.updating = true;
      state.error = false;
    },
    passTemplatesDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    passTemplatesNotDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Fetch
    fetchPassTemplates: (state, action: PayloadAction<PassTemplate>) => {
      state.updating = true;
      state.error = false;
    },
    passTemplatesFetched: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    passTemplatesNotFetched: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Create
  createPassTemplate,
  passTemplateCreated,
  passTemplateNotCreated,
  // Update
  updatePassTemplate,
  passTemplateUpdated,
  passTemplateNotUpdated,
  // Delete
  deletePassTemplate,
  passTemplateDeleted,
  passTemplateNotDeleted,
  // Delete All
  deletePassTemplates,
  passTemplatesDeleted,
  passTemplatesNotDeleted,
  // Fetch
  fetchPassTemplates,
  passTemplatesFetched,
  passTemplatesNotFetched,
} = PassTemplateSlice.actions;

// Export Reducer
export default PassTemplateSlice.reducer;
