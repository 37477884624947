import React from 'react';
import { Input } from 'antd';

interface ColorPickerProps {
  name: string;
  form: any;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ name, form }) => {
  const backgroundColor = form.getFieldValue(name) || '#ffffff';

  const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newColor = e.target.value;
    form.setFieldValue(name, newColor);
  };

  return (
    <Input.Group compact>
      <Input style={{ width: '15%', padding: 5 }} type="color" value={backgroundColor} onChange={handleColorChange} />
      <Input style={{ width: '85%' }} value={backgroundColor} placeholder="#ffffff" disabled />
    </Input.Group>
  );
};

export default ColorPicker;
