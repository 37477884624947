export enum PassTemplateSizeType {
  Standard = 0,
  Slim = 1,
  Square = 2,
}

export interface PassTemplateType {
  Name: string;
  Value: number;
  PassTemplate: PassTemplateSizeType;
}

export const PassTemplateTypes: PassTemplateType[] = [
  {
    Name: 'passTemplates.form.size.standard',
    Value: 0,
    PassTemplate: PassTemplateSizeType.Standard,
  },
  {
    Name: 'passTemplates.form.size.slim',
    Value: 1,
    PassTemplate: PassTemplateSizeType.Slim,
  },
  {
    Name: 'passTemplates.form.size.square',
    Value: 2,
    PassTemplate: PassTemplateSizeType.Square,
  },
];
