// Types
import { SignalREvent } from '../../../models/SignalREvent';

// Actions
import { passTemplateTenantsAssigned, passTemplateTenantsNotAssigned } from './PassTemplateTenants.redux';

// Events
const SignalREvents = {
  PassTemplateTenantsAssigned: `PassTemplateTenantsAssignedIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  PassTemplateTenantsNotAssigned: `PassTemplateTenantsNotAssignedIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Assign
  hubConnection.on(SignalREvents.PassTemplateTenantsAssigned, (msg) => {
    store.dispatch(passTemplateTenantsAssigned({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.PassTemplateTenantsNotAssigned, (msg) => {
    store.dispatch(passTemplateTenantsNotAssigned({ history, msg }));
  });
};
