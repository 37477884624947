import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Translated } from '../UI/Core';
import { Widget } from '../UI/Widget/Widget';
import { useAppSelector } from '../../hooks/App/useRedux';
import { Spinner } from '../UI/Spinner/Spinner';
import { PassTemplate, PassTemplateFormValues } from '../../models/PassTemplate';
import { PassTemplateSizeType } from '../../models/enums/PassTemplates/PassTemplateType';
import { PassTemplateBackgroundType } from '../../models/enums/PassTemplates/PassTemplateBackground';
import { BackgroundSizeType } from '../../models/enums/BackgroundSize';
import { PassTemplateOrientationType } from '../../models/enums/PassTemplates/PassTemplateOrientation';

interface PreviewCardProps {
  passTemplate: PassTemplate | null;
}

const AspectRatios: Record<PassTemplateSizeType, Record<PassTemplateOrientationType, number>> = {
  [PassTemplateSizeType.Standard]: {
    [PassTemplateOrientationType.Portrait]: 85 / 55,
    [PassTemplateOrientationType.Landscape]: 55 / 85,
  },
  [PassTemplateSizeType.Slim]: {
    [PassTemplateOrientationType.Portrait]: 85 / 25,
    [PassTemplateOrientationType.Landscape]: 25 / 85,
  },
  [PassTemplateSizeType.Square]: {
    [PassTemplateOrientationType.Portrait]: 1,
    [PassTemplateOrientationType.Landscape]: 1,
  },
};

const BackgroundSize: Record<BackgroundSizeType, string> = {
  [BackgroundSizeType.Auto]: 'auto',
  [BackgroundSizeType.Contain]: 'contain',
  [BackgroundSizeType.Cover]: 'cover',
};

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const PassTemplateCard = styled(Widget)`
  height: 25rem;
  width: auto;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 0px 2px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
`;

export const PreviewCard = ({ passTemplate }: PreviewCardProps) => {
  // Hooks
  const { updatingSettings } = useAppSelector(({ passTemplates }) => passTemplates);

  // Form Values
  const initialValues: PassTemplateFormValues = useMemo(
    () => ({
      Id: passTemplate?.Id,
      Name: passTemplate?.Name,
      BackgroundType: passTemplate?.BackgroundType,
      BackgroundImage: passTemplate?.BackgroundImage,
      BackgroundColor: passTemplate?.BackgroundColor,
      BackgroundSize: passTemplate?.BackgroundSize,
      Orientation: passTemplate?.Orientation,
      Type: passTemplate?.Type,
      EnabledForVisitors: passTemplate?.EnabledForVisitors,
      EnabledForEmployees: passTemplate?.EnabledForEmployees,
      Tenants: [],
    }),
    [passTemplate]
  );

  const SettingsForm = useCallback(
    () => (
      <CardContainer>
        <PassTemplateCard
          style={{
            aspectRatio:
              AspectRatios[initialValues.Type ?? PassTemplateSizeType.Standard][
                initialValues.Orientation ?? PassTemplateOrientationType.Landscape
              ],
            background:
              initialValues.BackgroundType === PassTemplateBackgroundType.Image
                ? `center / ${BackgroundSize[initialValues.BackgroundSize || BackgroundSizeType.Auto]} no-repeat url("${
                    initialValues.BackgroundImage?.Url
                  }") #eee`
                : initialValues.BackgroundColor || '#ffffff',
          }}
        />
      </CardContainer>
    ),
    [initialValues]
  );

  return (
    <Widget title={<Translated id="passTemplates.preview" />} styleName="gx-card-profile-sm">
      <Spinner spinning={!!updatingSettings}>
        <SettingsForm />
      </Spinner>
    </Widget>
  );
};
