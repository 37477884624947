import React from 'react';
import { Col, Row } from 'antd';

// Hooks
import { usePassTemplatesList } from '../../hooks/PassTemplates/usePassTemplatesList';

// Components
import { Container } from '../../components/UI/Base';
import { Table } from '../../components/UI/Table/Table';
import { Spinner } from '../../components/UI/Spinner/Spinner';
import { FormDrawer } from '../../components/UI/Drawer/FormDrawer';
import { BreadcrumbMenu } from '../../components/UI/Breadcrumb/BreadcrumbMenu';

export const PassTemplatesPage = () => {
  const {
    updating,
    getBreadcrumbMenuProps,
    getPassTemplatesDataProps,
    getPassTemplatesTableProps,
    getPassTemplatesCrudProps,
    getFormDrawerProps,
  } = usePassTemplatesList();

  return (
    <Spinner spinning={updating}>
      <BreadcrumbMenu {...getBreadcrumbMenuProps()} />

      <Container>
        <Row>
          <Col span={24}>
            <Table
              {...getPassTemplatesDataProps()}
              {...getPassTemplatesTableProps()}
              {...getPassTemplatesCrudProps()}
            />
          </Col>
        </Row>
      </Container>

      <FormDrawer {...getFormDrawerProps()} />
    </Spinner>
  );
};
