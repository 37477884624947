import React from 'react';
import { Button, Col, Row } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { ButtonSkeleton } from '../UI/Skeleton/ButtonSkeleton';
import { Translated } from '../UI/Core';
import { DetailHeader } from '../App/AppLayout/DetailHeader';
import { PassTemplate } from '../../models/PassTemplate';

const StyledSpan = styled.span`
  margin-left: 5px !important;
`;

interface ProfileHeaderProps {
  passTemplateData: PassTemplate | undefined;
  pending: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  showDeleteConfirm: (passTemplate: PassTemplate | undefined) => void;
}

export const ProfileHeader = ({
  passTemplateData,
  pending,
  setOpen,
  showDeleteConfirm,
}: ProfileHeaderProps) => {

  return (
    <DetailHeader>
      <div className="gx-profile-banner-top">
        <div className="gx-profile-banner-top-left">
          <div className="gx-profile-banner-avatar-info">
            <Row>
              <Col>
                <h2 className="gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light">
                  {pending ? <ButtonSkeleton width={240} height={16} /> : passTemplateData?.Name}
                </h2>
                <div className="gx-mb-0 gx-fs-lg">
                  {pending ? <ButtonSkeleton width={160} height={16} /> : passTemplateData?.Id}
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="gx-profile-banner-top-right">
          <Button ghost type="primary" icon={<EditOutlined />} key="Edit" onClick={() => setOpen(true)}>
            <StyledSpan>
              <Translated id="app.edit" defaultMessage="Edit" />
            </StyledSpan>
          </Button>
          <Button
            ghost
            danger
            icon={<DeleteOutlined />}
            key="Delete"
            onClick={() => showDeleteConfirm(passTemplateData)}
          >
            <StyledSpan>
              <Translated id="app.delete" defaultMessage="Delete" />
            </StyledSpan>
          </Button>
        </div>
      </div>
    </DetailHeader>
  );
};
