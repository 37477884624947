import React from 'react';
import { Col, Empty, Row, Skeleton, Tabs } from 'antd';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

// Models
import { PassTemplate } from '../../models/PassTemplate';

// Hooks
// Components
import { Widget } from '../UI/Widget/Widget';
import { Spinner } from '../UI/Spinner/Spinner';
import { Translated } from '../UI/Core';
import { usePassTemplateTenants } from '../../hooks/PassTemplates/PassTemplateTenants/usePassTemplateSettings';

// Styled
const StyledWidget = styled(Widget)`
  & .ant-card-body {
    padding: 0;
  }
`;
const StyledTabs = styled(Tabs)`
  & .ant-tabs-nav-wrap {
    margin-left: 24px;
  }

  & .ant-tabs-nav {
    height: 58px;
  }

  & .ant-tabs-content {
    padding: 0px 24px 16px;
  }
`;

// Props
interface PassTemplateTenantsProps {
  passTemplateResponse: PassTemplate | null;
  fetchPassTemplate: (params: any) => Promise<void>;
  updatingPassTemplate: boolean;
}

// Component
export const PassTemplateSettings = ({
  passTemplateResponse,
  fetchPassTemplate,
  updatingPassTemplate,
}: PassTemplateTenantsProps) => {
  // Identifiers Hook
  const { loading, updating, passTemplateTenants, PassTemplateTenantsManagementForm } = usePassTemplateTenants({
    passTemplateResponse,
    fetchPassTemplate,
    updatingPassTemplate,
  });

  // Render Methods
  const renderLoading = () => [
    {
      key: '0',
      label: <Translated id="app.loading" />,
      children: (
        <Row>
          <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <Skeleton active />
          </Col>
        </Row>
      ),
    },
  ];

  const renderEmpty = () => [
    {
      key: '0',
      label: <Translated id="identifierTypes.Tenants.tab" />,
      children: (
        <Row>
          <Col xs={24}>
            <Empty
              description={
                <span>
                  <Translated id="identifierTypes.Tenants.empty" />
                </span>
              }
            />
          </Col>
        </Row>
      ),
    },
  ];

  const renderForm = () => [
    {
      key: '0',
      label: <Translated id="identifierTypes.Tenants.tab" />,
      children: (
        <Spinner spinning={updating}>
          <PassTemplateTenantsManagementForm />
        </Spinner>
      ),
    },
  ];

  const renderItems = () => {
    if (loading) return renderLoading();
    if (!passTemplateTenants || isEmpty(passTemplateTenants)) return renderEmpty();
    return renderForm();
  };

  // Component Render
  return (
    <StyledWidget styleName="gx-card-widget">
      <StyledTabs items={renderItems()} />
    </StyledWidget>
  );
};
