import React, { useCallback, useMemo } from 'react';

// Hooks
import { usePassTemplatesRedux } from './usePassTemplatesRedux';
import { useFormDrawer } from '../Drawer/useFormDrawer';
import { useData } from '../App/useData';

// Data
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { passTemplateTableColumns } from '../../data/Table/PassTemplateTableColumns';
import { appBreadcrumbs } from '../../data/Breadcrumbs/Breadcrumbs';

// Components
import { Translated } from '../../components/UI/Core';
import { LocalStorageKeys } from '../../constants/Utils/LocalStorage';
import { getTableColumns, saveHiddenTableColumns } from '../../constants/Utils/UIFunctions';
import { BasicIcon } from '../../components/UI/Icon/BasicIcon';

export const usePassTemplatesList = <T extends object>() => {
  // Data
  const { data: passTemplates, pageCount, totalCount, fetch, pending } = useData(ApiEndpoints.passTemplates.list, null);
  const data = useMemo(() => passTemplates, [passTemplates]);

  // Redux
  const { formOptions, updating, error, showDeleteAllConfirm } = usePassTemplatesRedux<T>();

  // Form Drawer
  const { AddButton, getFormDrawerProps } = useFormDrawer<T>({ formOptions, updating, error });

  // Props
  const getBreadcrumbMenuProps = useCallback(() => ({ breadcrumbs: appBreadcrumbs.passTemplates.list }), []);
  const getPassTemplatesDataProps = useCallback(
    () => ({
      storageKey: LocalStorageKeys.PassTemplates.table.hiddenColumns,
      title: (
        <>
          <BasicIcon className="icon icon-card" style={{ marginRight: 8 }} />
          <Translated id="appMenu.passTemplates" />
        </>
      ),
      data: data ?? [],
      columns: getTableColumns(passTemplateTableColumns, LocalStorageKeys.PassTemplates.table.hiddenColumns),
      fetchData: fetch,
    }),
    [fetch, data]
  );
  const getPassTemplatesTableProps = useCallback(
    () => ({
      pending,
      updating,
      pageCount,
      totalCount,
    }),
    [pending, updating, pageCount, totalCount]
  );
  const getPassTemplatesCrudProps = useCallback(
    () => ({
      AddButton,
      endpoint: getFormDrawerProps().endpoint,
      showDeleteAllConfirm,
      saveHiddenTableColumns,
    }),
    [AddButton, getFormDrawerProps, showDeleteAllConfirm]
  );

  return useMemo(
    () => ({
      updating,
      getBreadcrumbMenuProps,
      getPassTemplatesDataProps,
      getPassTemplatesTableProps,
      getPassTemplatesCrudProps,
      getFormDrawerProps,
    }),
    [
      updating,
      getBreadcrumbMenuProps,
      getPassTemplatesDataProps,
      getPassTemplatesTableProps,
      getFormDrawerProps,
      getPassTemplatesCrudProps,
    ]
  );
};
